import estados from '@/assets/jsons/estados.json'
import FieldFile from '@/components/fields/FieldFile'
import FieldInput from '@/components/fields/FieldInput'
import FieldSelect from '@/components/fields/FieldSelect'
import FieldOptions from '@/components/fields/FieldOptions'

const required = true
const isDisabled = true
const classCols2 = 'col-xs-12 col-sm-12 col-md-2 col-lg-2'
const classCols3 = 'col-xs-12 col-sm-12 col-md-3 col-lg-3'
const classCols4 = 'col-xs-12 col-sm-12 col-md-4 col-lg-4'
const classCols5 = 'col-xs-12 col-sm-12 col-md-5 col-lg-5'
const classCols6 = 'col-xs-12 col-sm-12 col-md-6 col-lg-6'
const classCols7 = 'col-xs-12 col-sm-12 col-md-7 col-lg-7'
const classCols8 = 'col-xs-12 col-sm-12 col-md-8 col-lg-8'
const classCols9 = 'col-xs-12 col-sm-12 col-md-9 col-lg-9'
const classCols12 = 'col-xs-12 col-sm-12 col-md-12 col-lg-12'

// const optionsYesNo = [
//   { label: 'Sim', value: 'S' },
//   { label: 'Não', value: 'N' }
// ]

export const checkbox = []

const convertData = (val) => {
  const [ano, mes, dia] = val.split('-')
  return `${dia}/${mes}/${ano}`
}

const ufs = estados.map(({ sigla }) => ({ label: sigla, value: sigla }))

const findCities = (form, key) =>
  estados
    ?.filter(({ sigla }) => sigla === form?.[key])
    ?.map(({ cidades }) => cidades.map((cidade) => ({ label: cidade, value: cidade })))
    .flat()

export const sections = [
  {
    titulo: 'Identificação do Candidato',
    icon: 'fas fa-user',
    class: classCols12,
    inputs: [
      {
        required,
        key: 'nome',
        type: 'text',
        label: 'Nome:',
        class: classCols8,
        getclass: classCols8,
        component: FieldInput,
        placeholder: 'Informe o nome'
      },
      {
        required,
        class: classCols4,
        mask: '##/##/####',
        component: FieldInput,
        key: 'dataNascimento',
        getconvert: convertData,
        getkey: 'data_nascimento',
        label: 'Data de Nascimento:',
        placeholder: 'Ex: 20/10/1990'
      },
      {
        required,
        isDisabled,
        key: 'cpf',
        type: 'text',
        label: 'CPF:',
        class: classCols6,
        component: FieldInput,
        mask: '###.###.###-##'
      },
      {
        required,
        key: 'repeatcpf',
        onPaste: false,
        hidePrint: true,
        type: 'text',
        label: 'Repita o CPF:',
        class: classCols6,
        component: FieldInput,
        mask: '###.###.###-##'
      },
      {
        required,
        key: 'rg',
        label: 'RG:',
        type: 'text',
        class: classCols5,
        getclass: classCols4,
        component: FieldInput
      },
      {
        required,
        type: 'text',
        class: classCols4,
        key: 'orgaoEmissor',
        getkey: 'rg_orgao_emissor',
        component: FieldInput,
        label: 'Orgão Emissor:'
      },
      {
        required,
        class: classCols3,
        getclass: classCols4,
        mask: '##/##/####',
        component: FieldInput,
        key: 'dataEmissao',
        getkey: 'rg_data_emissao',
        getconvert: convertData,
        placeholder: 'Ex: 20/10/2000',
        label: 'Data de Emissão:'
      }
    ]
  },
  {
    titulo: 'Endereço',
    icon: 'fas fa-house-user',
    class: classCols12,
    inputs: [
      {
        key: 'pais',
        required,
        label: 'País:',
        type: 'text',
        component: FieldInput,
        class: classCols5
      },
      {
        key: 'cep',
        required,
        mask: '##.###-###',
        label: 'CEP:',
        type: 'text',
        component: FieldInput,
        class: classCols7
      },
      {
        key: 'endereco',
        required,
        label: 'Logradouro:',
        type: 'text',
        component: FieldInput,
        class: classCols9
      },
      {
        key: 'numero',
        required,
        label: 'Número:',
        mask: '##########',
        component: FieldInput,
        class: classCols3
      },
      {
        key: 'complemento',
        label: 'Complemento:',
        type: 'text',
        component: FieldInput,
        class: classCols12,
        getclass: classCols5
      },
      {
        required,
        key: 'uf',
        label: 'UF:',
        class: classCols2,
        options: ufs,
        component: FieldSelect
      },
      {
        required,
        key: 'cidade',
        label: 'Cidade:',
        class: classCols5,
        options: (form) => findCities(form, 'uf'),
        component: FieldSelect
      },
      {
        key: 'bairro',
        required,
        label: 'Bairro:',
        type: 'text',
        component: FieldInput,
        class: classCols5
      }
    ]
  },
  {
    titulo: 'Complementos',
    icon: 'fas fa-plus-square',
    class: classCols12,
    inputs: [
      {
        required,
        type: 'text',
        key: 'nacionalidade',
        label: 'Nacionalidade:',
        component: FieldInput,
        class: classCols4
      },
      {
        required,
        key: 'naturalUF',
        getkey: 'natural_uf',
        label: 'Natural da UF:',
        class: classCols3,
        options: ufs,
        component: FieldSelect
      },
      {
        required,
        key: 'naturalCidade',
        getkey: 'natural_cidade',
        label: 'Natural da cidade:',
        class: classCols5,
        options: (form) => findCities(form, 'naturalUF'),
        component: FieldSelect
      },
      {
        required,
        key: 'sexo',
        label: 'Gênero:',
        class: classCols4,
        component: FieldSelect,
        options: [
          { label: 'Feminino', value: 'F' },
          { label: 'Masculino', value: 'M' }
        ]
      },
      {
        required,
        key: 'estadoCivil',
        getkey: 'estado_civil',
        label: 'Estado civil:',
        class: classCols4,
        component: FieldSelect,
        options: [
          { label: 'Solteiro', value: 'solteiro' },
          { label: 'Casado', value: 'casado' },
          { label: 'Separado', value: 'separado' },
          { label: 'Divorciado', value: 'divorciado' },
          { label: 'Viúvo', value: 'viúvo' }
        ]
      }
    ]
  },
  {
    titulo: 'Contato',
    icon: 'fas fa-info-circle',
    class: classCols12,
    inputs: [
      {
        key: 'telefoneCelular',
        getkey: 'telefone_celular',
        required,
        label: 'Telefone celular:',
        type: 'text',
        mask: '(##) #####-####',
        component: FieldInput,
        class: classCols4
      },
      {
        key: 'telefoneResidencial',
        getkey: 'telefone_residencial',
        label: 'Telefone residencial:',
        type: 'text',
        component: FieldInput,
        mask: '(##) ####-####',
        class: classCols4
      },
      {
        class: classCols4,
        mask: '(##) ####-####',
        component: FieldInput,
        key: 'telefoneComercial',
        getkey: 'telefone_comercial',
        label: 'Telefone Comercial:'
      },
      {
        required,
        key: 'email',
        type: 'email',
        label: 'Email:',
        class: classCols6,
        onPaste: false,
        component: FieldInput
      },
      {
        required,
        key: 'repeatEmail',
        type: 'email',
        onPaste: false,
        hidePrint: true,
        label: 'Repita o Email:',
        class: classCols6,
        component: FieldInput
      }
    ]
  },
  {
    titulo: 'Filiação',
    icon: 'fas fa-users',
    class: classCols12,
    inputs: [
      {
        required,
        type: 'text',
        label: 'Nome da mãe:',
        class: classCols6,
        key: 'nomeMae',
        getkey: 'nome_mae',
        component: FieldInput,
        placeholder: 'Nome da mãe'
      },
      {
        type: 'text',
        label: 'Nome do Pai:',
        class: classCols6,
        getkey: 'nome_pai',
        key: 'nomePai',
        component: FieldInput,
        placeholder: 'Nome do pai'
      }
    ]
  },
  {
    titulo: 'Deficiência',
    icon: 'fas fa-wheelchair',
    class: classCols12,
    inputs: [
      {
        required,
        key: 'possuiDeficiencia',
        getkey: 'possui_deficiencia',
        class: classCols6,
        label: 'É deficiente?',
        component: FieldOptions,
        options: [
          { label: 'Sou deficiente', value: 'S' },
          { label: 'Não sou deficiente', value: 'N' }
        ]
      },
      {
        key: 'comprovanteDeficiencia',
        accept: 'application/pdf',
        required,
        label: 'Cópia do Laudo:',
        component: FieldFile,
        hidePrint: true,
        class: classCols6,
        conditional: (form) => form.possuiDeficiencia === 'S'
      }
    ]
  },
  {
    titulo: 'Condição Especial',
    icon: 'fas fa-universal-access',
    class: classCols12,
    inputs: [
      {
        required,
        key: 'possuiCondicaoEspecial',
        getkey: 'necessida_condicao_especial',
        class: classCols12,
        label: 'Precisa de condição especial para a realização das provas?',
        component: FieldOptions,
        options: [
          { label: 'Sim', value: 'S' },
          { label: 'Não', value: 'N' }
        ]
      },
      {
        required,
        class: classCols12,
        type: 'textarea',
        component: FieldInput,
        hidePrint: true,
        key: 'detalheCondicaoEspecial',
        label: 'Descrição da condição especial',
        conditional: (form) => form.possuiCondicaoEspecial === 'S'
      }
    ]
  },
  {
    titulo: 'Opção',
    icon: 'fa-solid fa-check-to-slot',
    class: classCols12,
    inputs: [
      {
        required,
        key: 'cargo',
        label: 'Cargo:',
        class: classCols12,
        component: FieldSelect,
        options: [
          // { value: 'CARGOS DE NÍVEL SUPERIOR', label: 'CARGOS DE NÍVEL SUPERIOR', disabled: true },
          { value: 'AGENTE COMUNITÁRIO DE SAÚDE - SEDE / URBANO', label: 'AGENTE COMUNITÁRIO DE SAÚDE - SEDE / URBANO' },
          { value: 'AGENTE COMUNITÁRIO DE SAÚDE - DISTRITO VISTA ALEGRE / RURAL', label: 'AGENTE COMUNITÁRIO DE SAÚDE - DISTRITO VISTA ALEGRE / RURAL' },
        ]
      }
    ]
  },
  // {
  //   titulo: 'Isenção',
  //   icon: 'fa-solid fa-clipboard-list',
  //   class: classCols12,
  //   inputs: [
  //     {
  //       key: 'isencaoInscricao',
  //       getkey: 'isencao_taxa_inscricao',
  //       required,
  //       label: 'Pedido de isenção de taxa de inscrição:',
  //       options: optionsYesNo,
  //       component: FieldOptions,
  //       class: classCols6
  //     },
  //     {
  //       required,
  //       key: 'opcaoIsencaoInscricao',
  //       getkey: 'opcao_isencao',
  //       label: 'Opção da isenção:',
  //       class: classCols6,
  //       component: FieldSelect,
  //       options: [{ label: 'Inscrito no CadUnico', value: 'Inscrito no CadUnico' }],
  //       conditional: (i) => i.isencaoInscricao === 'S' || i.isencao_taxa_inscricao === 'S'
  //     },
  //     {
  //       key: 'isencaotaxainscricao',
  //       accept: 'application/pdf',
  //       required,
  //       label: 'Cópia da declaração/cartão ou folha resumo cadastro único, em arquivo em formado <b>PDF</b>:',
  //       component: FieldFile,
  //       class: classCols12,
  //       hidePrint: true,
  //       conditional: (form) => form.isencaoInscricao === 'S'
  //     }
  //   ]
  // }
]

/* 
POSSIVEIS PARAMETROS
key           => propriuedade do objeto        
required      => Obrigatório    
label         => descrição do field
type          => tipo do field
options       => Opções para o caso seja select 
component     => Component a ser renderizado    
class         => Classes de estilo
conditional   => Validação para exibir ou não      
placeholder   => Caso seja campo de texto      
mask          => Mascara para validação
*/
