import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import store from '@/store'
import VueMask from 'v-mask'
import routes from '@/routes'
import vSelect from 'vue-select'
import VueRouter from 'vue-router'
import Sweet from '@/assets/js/sweet'
import 'vue-select/dist/vue-select.css'
import Funcoes from '@/assets/js/functions'

// SYSTEM CONTROLLERS
const city = 'vicentina'
Vue.prototype.$ps = 'ps3'
Vue.prototype.$city = city
Vue.prototype.$psCity = 'vicentinaps3'
Vue.prototype.$inscricaoAberta = false
Vue.prototype.$siteInstituto = 'https://ibcgp.org.br'

const baseApiURL = 'https://api.ibcgp.org.br'
axios.defaults.baseURL = baseApiURL
axios.defaults.headers.common['access'] = city

Vue.use(VueMask)
Vue.use(VueRouter)
Vue.prototype.$f = new Funcoes()
Vue.prototype.$sweet = new Sweet()
Vue.component('SelectComp', vSelect)

const router = new VueRouter({ routes, base: '/' })

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app')
