<template>
  <div>
    <Impressao v-if="showPrint" :isHomePage="true" :formulario="formulario" ref="impressao" id="bg-white" class="bg-white" />

    <div class="main">
      <div class="container px-md-0 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <form @submit.prevent="salvar" class="form">
              <div class="text-center pt-4 mb-2">
                <img class="img-brasao" src="../assets/images/brasao.jpeg" />
              </div>

              <h4 class="text-center mb-1">Ficha de inscrição</h4>

              <div class="px-4 mx-md-5 my-3">
                <div class="bg-light px-3 py-2 lei">
                  <p class="font-12">
                    O IBCGP (Instituto Brasileiro de Ciência em Gestão Pública) atende à Lei nº 13.709/18 – Lei Geral de Proteção de Dados
                    Pessoais (LGPD), que dispõe sobre o tratamento de dados pessoais com o objetivo de proteger os direitos fundamentais de
                    liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural.
                  </p>
                </div>
              </div>

              <div class="row justify-content-center">
                <div :class="['col-md-12 col-11 px-sm-5 pt-4 pb-1', form.class]" v-for="(form, ind) in forms" :key="`${ind}-form`">
                  <h5 class="mb-2 bg-light py-1 px-2">
                    <i :class="[form.icon, 'mr-1']"></i>
                    {{ form.titulo }}
                  </h5>

                  <div class="row">
                    <div :class="[input.class]" v-for="(input, indInput) in form.inputs" :key="`${indInput}-input`">
                      <div :class="input.conditional ? (input.conditional(formulario) ? 'enter-effect mb-3' : 'out-effect') : 'mb-3'">
                        <component
                          :required="input.required || (formulario.sexo === 'M' && input.key === 'obrigacoesMilitares')"
                          v-if="input.conditional ? input.conditional(formulario) : true"
                          :options="getOptions(input, formulario)"
                          :placeholder="input.placeholder"
                          :is-disabled="input.isDisabled || isDisabled || (!isValidBirthDate && input.key !== 'dataNascimento')"
                          :upper-case="input.upperCase"
                          :onPaste="input.onPaste"
                          :accept="input.accept"
                          :is="input.component"
                          :type="input.type"
                          :mask="input.mask"
                          :id="input.key"
                          :class="
                            (formulario.situacaoInscricao === 'I' && !formulario[input.key] && input.required) ||
                            (input.key === 'nomeCandidato' && !isValidName && 'incomplet')
                          "
                          :label="input.label"
                          v-model="formulario[input.key]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="d-inline-flex justify-content-center align-items-center w-100 height-30 mt-2"
                v-for="item in checkbox"
                :key="item.key"
              >
                <label class="text-right pointer mr-2 form-check-label w-55" :for="item.key">
                  {{ item.label }}
                </label>

                <span class="d-inline-flex align-items-center w-45">
                  <input class="pointer mb-1 check-confirm" type="checkbox" :id="item.key" v-model="formulario[item.key]" />
                  <a class="pl-2" :href="item.link" target="_blank">{{ item.textLink }}</a>
                </span>
              </div>

              <div v-if="!isDisabled" class="col-12 text-center pb-4">
                <button
                  type="submit"
                  :disabled="!isConfirmed || !isValidBirthDate"
                  :class="['btn mt-4', !isConfirmed || !isValidBirthDate ? 'btn-secondary' : 'btn-primary']"
                >
                  Cadastrar
                </button>
              </div>

              <div v-else-if="formulario.cpf" class="col-12 text-center pb-4">
                <span class="btn mt-4 btn-secondary pointer" @click="logOut()">Sair</span>
                <span class="btn mt-4 btn-primary pointer ml-4" @click="imprimir()">Imprimir</span>
              </div>

              <div class="row justify-content-center">
                <div class="col-10 col-md-6">
                  <p class="contact text-center pb-4 text-secondary">
                    Se tiver alguma dúvida ou se tiver algum problema com sua inscricao, entrar em contato com o email
                    <a :href="`mailto:${suporte}`">{{ suporte }}</a
                    >.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Informacoes class="mt-4" />

    <Footer />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Footer from '@/components/Footer'
import Impressao from '@/components/Impressao'
import { checkbox, sections as forms } from './formInputs'
import Informacoes from '@/components/Informacoes'

export default {
  name: 'FormComp',

  components: {
    Footer,
    Impressao,
    Informacoes
  },

  data() {
    return {
      suporte: 'suporte@ibcgp.org.br',
      duvidas: 'duvidas@ibcgp.org.br',
      checkbox,
      isDisabled: false,
      showPrint: false,
      isLoadingId: false,
      forms,
      formulario: {
        nome: '',
        cpf: '',
        repeatcpf: '',
        rg: '',
        orgaoEmissor: '',
        dataEmissao: '',
        pais: '',
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        uf: '',
        cidade: '',
        bairro: '',
        nacionalidade: '',
        naturalUF: '',
        naturalCidade: '',
        sexo: '',
        dataNascimento: '',
        estadoCivil: '',
        telefoneCelular: '',
        telefoneResidencial: '',
        telefoneComercial: '',
        email: '',
        repeatEmail: '',
        nomeMae: '',
        nomePai: '',
        possuiDeficiencia: '',
        possuiCondicaoEspecial: '',
        detalheCondicaoEspecial: '',
        cargo: '',
        isencaoInscricao: '',
        opcaoIsencaoInscricao: '',
        isencaotaxainscricao: '',
        id: '',
        createdAt: ''
      }
    }
  },

  created() {
    if (!this.profile.cpf) return this.$router.push({ name: 'home' })

    this.formulario = { ...this.formulario, ...this.profile }
  },

  methods: {
    imprimir() {
      setTimeout(() => {
        this.showPrint = true
      }, 200)
      setTimeout(() => {
        this.$refs.impressao.generatePdf()
      }, 500)
    },

    logOut() {
      this.$router.push({ name: 'home' })
    },

    getOptions(input, formulario) {
      if (typeof input.options === 'function') return input?.options(formulario, input.key) || []
      return input.options
    },

    async saveDoc(arquivo, id, tipo) {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const dataForm = new FormData()
      dataForm.append('arquivo', arquivo)
      dataForm.append('diretorio', `concurso-ps/${this.$city}/${this.$ps}/${tipo}`)
      dataForm.append('identificador', id)
      return axios.post('/arquivo', dataForm, { headers }).catch((err) => err)
    },

    async salvar() {
      if (!this.isValidName || !this.isValidMail || !this.isValidCPF) {
        let concatMsg = ''
        if (!this.isValidName) concatMsg = 'seu nome'
        if (!this.isValidMail) concatMsg = 'seu email'
        if (!this.isValidCPF) concatMsg = 'seu CPF'
        return this.$sweet.sweetMensagem(`Por favor, verifique se ${concatMsg} está correto.`)
      }

      if (this.formulario.dataEmissao.length !== 10) {
        return this.$sweet.sweetMensagem(`Por favor, verifique se a data de emissão do RG está correto.`)
      }

      if (this.formulario.dataNascimento.length !== 10) {
        return this.$sweet.sweetMensagem(`Por favor, verifique se a data de nascimento está correto.`)
      }

      const msg = 'Confirma que preencheu todos seus dados corretamente?'
      const html = `<p>O preenchimento incorreto implicará no indeferimento da inscrição. 
        Caso seu e-mail esteja correto você receberá a senha de acesso em seu e-mail imediatamente.</p>`

      const { isConfirmed } = await this.$sweet.sweetQuestionPersonalizado(msg, html)
      if (!isConfirmed) return

      this.$sweet.sweetWait('Aguarde enquanto salvamos suas informações.')

      const [dia, mes, ano] = this.formulario.dataNascimento.split('/')
      const [diaE, mesE, anoE] = this.formulario.dataEmissao.split('/')
      const params = {
        ...this.formulario,
        dataNascimento: `${ano}-${mes}-${dia}`,
        dataEmissao: `${anoE}-${mesE}-${diaE}`,
        cargo: this.formulario.cargo
      }

      delete params.id
      delete params.form
      delete params.isencaotaxainscricao
      if (params.cancelarInscricao) await axios.put(`/formulario/${this.$city}/${this.$psCity}/cancelar-inscricao/${params.cpf}`)
      const { data, status } = await axios.post(`formulario/${this.$city}/${this.$psCity}`, null, { params }).catch((e) => e.response)
      this.formulario.id = data
      const newDate = new Date()
      const day = `${newDate.getDate()}`.padStart(2, '0')
      const month = `${newDate.getMonth() + 1}`.padStart(2, '0')
      const year = newDate.getFullYear()

      this.formulario.createdAt = `${year}-${month}-${day}T`

      const { isencaotaxainscricao, comprovanteDeficiencia } = this.formulario
      if (isencaotaxainscricao && !isNaN(data)) {
        await this.saveDoc(isencaotaxainscricao, data, 'isencoes')
      }
      if (comprovanteDeficiencia && !isNaN(data)) {
        await this.saveDoc(comprovanteDeficiencia, data, 'laudos-deficiencia')
      }

      if (status >= 200 && status < 300) {
        this.isDisabled = true
        this.$sweet.sweetSuccess(
          `
            <h5 class="mb-3">Inscrição realizada com sucesso</h5>

            <div class="bg-light py-3 px-4 mt-3">
              <h6 class="text-success mb-3">Atenção!</h6>
              <p class="text-modal text-center">Uma senha provisória foi enviada ao e-mail cadastrado em sua ficha de isncrição para acesso à área do candidato onde serão disponibilizados a ficha de inscrição, links e informações. <br><br> Caso não receba o e-mail com a senha provisória imediatamente após a inscrição, verifique a caixa de spam e se seu e-mail não está sem espaço.</p>
            </div>
          `,
          15000
        )
        axios.defaults.headers['Authorization'] = ''
        return
      }

      this.$sweet.sweetMensagem(data || 'Erro ao salvar os dados, tente novamente. Caso o erro persista, entre em contato conosco.')
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' }),

    isValidName() {
      const str = this.formulario?.nomeCandidato?.replace(/ /g, '')
      const regex = /\W|\d|_/
      return !regex.test(str)
    },

    isValidBirthDate() {
      const [dia, mes, ano] = this.formulario?.dataNascimento?.split('/') || ''
      if (!dia || !mes || !ano || ano.length < 4) return false

      const dataAtual = new Date()
      const limitDate = new Date(dataAtual.getFullYear() - 18, dataAtual.getMonth(), dataAtual.getDate())
      const birthDate = new Date(ano, mes - 1, dia)
      if (birthDate > limitDate) return false
      return true
    },

    isValidCPF() {
      return this.formulario?.repeatcpf === this.formulario.cpf
    },

    isConfirmed() {
      const inputs = [...this.forms.map(({ inputs }) => inputs).flat()]
      const isFill = inputs.reduce((acc, { key, required, conditional }) => {
        if (!acc) return acc

        const isVisible = conditional ? conditional(this.formulario) : true
        if (!this.formulario[key] && required && isVisible) return false
        return true
      }, true)

      return isFill
    },

    isValidMail() {
      var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      const isEqual = this.formulario?.email === this.formulario.repeatEmail
      return Boolean(this.formulario.email.match(mailformat)) && isEqual
    },

    hasId() {
      return Boolean(this.formulario.id)
    }
  },

  watch: {
    'formulario.cep': {
      async handler(cep) {
        const filtered = cep.replace(/[^0-9]+/g, '')
        if (filtered.length !== 8) return

        const location = await fetch(`https://viacep.com.br/ws/${filtered}/json/`).then((r) => r.json())

        this.formulario.cidade = location?.localidade || ''
        this.formulario.uf = location?.uf || ''
        this.formulario.bairro = location?.bairro || ''
        this.formulario.endereco = location?.logradouro || ''
      }
    }
  }
}
</script>

<style lang="sass">
.main
  background-color: #f2f2f2 !important
  .container
    background-color: #f2f2f2 !important
h5
  margin-bottom: 10px !important
  color: #082772 !important
.form-control
  max-height: 33px !important
  font-weight: 200
  font-size: 14px
  padding: 0px 10px !important
  border: 1px solid rgba(25,25,100, .3) !important
  &:focus, &:active
   border: 1px solid rgba(45,45,150, .8) !important
.text-area
  max-height: inherit !important
  padding-top: 5px !important
label, p
  margin-bottom: 3px
  font-weight: 600
  font-size: 14px
  color: #082772 !important
  user-select: none
.form
  background-color: #fff
  box-shadow: 1px 2px 10px 3px rgba(45,45,45,.15)
  border-radius: 5px
.check-confirm
  width: 20px
.height-30
  height: 30px
.w-55
  width: 55%
.w-45
  width: 45%
@media (max-width: 500px)
  .container
    width: 100% !important
    margin-left: 0px !important
    margin-right: 0px !important
  h5
    font-size: 16px !important

.enter-effect
  max-height: 100vh
  transition: all 0.5s ease-out
  min-height: 58px
  opacity: 1
.out-effect
  min-height: 0px
  max-height: 0px
  overflow: hidden
  height: 0px
  opacity: 0
  transition: all 0.5s
h4
  color: #545454 !important
.contact
  line-height: 13px
  font-size: 12px
.incomplet
  background-color: #ff6f6f1a
  border-radius: 5px
  .label
    color: #a80a0a !important
  .form-control, .vs__dropdown-toggle, .form-check-input
    border-color: #a80a0a !important
.px-3-important
  padding-left: .8rem !important
  padding-right: .8rem !important
.bg-light
  background-color: #f0f3f5 !important
.img-brasao
  width: 100px !important
  margin: auto !important
.lei
  border-radius: 6px
.font-12
  font-size: 12px
</style>
